import Chart from 'chart.js/auto';

const tagName = 'financial-graph';
const template = document.createElement('template');
template.innerHTML = `<div><canvas class="graph"></canvas></div>`;

const defaultValue = {
  "currency": "€",
  "GMV": [ null ],
  "revenue": [ null ],
  "ebitda": [ null ],
  "ebit": [ null ],
  "gross_profit":[ null ],
  "years": [ "2019" ]
};

class ChartGraph extends HTMLElement {
  constructor() {
    super();
    this.innerValue = defaultValue;
  }

  static get observedAttributes() {
    return [ 'value' ];
  }

  static convertToInnerValue(str) {
    if (str === 'None' || str === null)
      return defaultValue;
    return JSON.parse(str);
  }

  safeSetAttribute(name, value) {
    if (this.getAttribute(name) !== value) this.setAttribute(name, value);
  }

  attributeChangedCallback(name, oldVal, newVal) {
    this[name] = newVal;
  }

  set value(value) {
    this.safeSetAttribute('value', value);
    this.innerValue = ChartGraph.convertToInnerValue(value);
    if (this.graph)
      this._redrawGraph();
  }

  get value() {
    return this.getAttribute('value')
  }

  get type() {
    return 'custom-financial-graph';
  }

  connectedCallback() {
    // Initialize properties that depend on light DOM
    this.innerValue = ChartGraph.convertToInnerValue(this.getAttribute('value')) || this.innerValue;

    var element = document.importNode(template.content, true);
    this.appendChild(element);
    this.graph = this._init(this.querySelector('.graph'));

    this._redrawGraph();
  }

  disconnectedCallback() {
    /* do cleanup stuff here */
  }

  _alignZeros() {
    let datasets = this.graph.data.datasets;
    let options = this.graph.options;
    let axes = [options.scales['right-axis'], options.scales['left-axis']];

    // Reset previous calculation
    axes.forEach(axis => {
      axis.min_value = undefined;
      axis.max_value = undefined;
      axis.min_ratio = undefined;
      axis.max_ratio = undefined;
    });
    // Determine overall max/min values for each dataset
    datasets.forEach(line => {
      let axis = line.yAxisID ? axes.filter(ax => ax.id === line.yAxisID)[0] : axes[0];
      axis.min_value = Math.min(...line.data, axis.min_value || 0);
      axis.max_value = Math.max(...line.data, axis.max_value || 0);
    });
    // Which gives the overall range of each axis
    axes.forEach(axis => {
      // ensure zero is in range
      axis.min_value = axis.min_value < 0 ? axis.min_value : 0;
      axis.max_value = axis.max_value > 0 ? axis.max_value : 0;
      axis.range = axis.max_value - axis.min_value !== 0 ? axis.max_value - axis.min_value : 1;

      // normalize numbers
      let ticks = Math.pow(10, Math.floor(Math.log10(axis.range))) / 2;

      axis.max_value = Math.ceil(axis.max_value / ticks) * ticks;
      axis.max_value = axis.max_value !== 0 ? axis.max_value : 1;
      ticks = axis.min_value < 0 ? -ticks : ticks;
      axis.min_value = Math.ceil(axis.min_value / ticks) * ticks;

      // Express the min / max values as a fraction of the overall range
      axis.range = axis.max_value - axis.min_value !== 0 ? axis.max_value - axis.min_value : 1;
      axis.min_ratio = axis.min_value / axis.range;
      axis.max_ratio = axis.max_value / axis.range;
    });
    // Find the largest of these ratios
    let largest = axes.reduce((a, b) => ({
      min_ratio: Math.min(a.min_ratio, b.min_ratio),
      max_ratio: Math.max(a.max_ratio, b.max_ratio)
    }));
    // Then scale each axis accordingly
    // axes.forEach(axis => {
    //     axis.ticks = axis.ticks || { }
    //     axis.ticks.min = largest.min_ratio * axis.range
    //     axis.ticks.max = largest.max_ratio * axis.range
    // });
  }

  _redrawGraph() {
    let ratio = function(num, den) {
      var res = [];
      for(var i=0;i<num.length;i++){
        res.push(num[i] != null && den[i] ? num[i]/den[i] : null);
      }
      return res;
    }

    let values = this.innerValue;
    this.graph.options.scales['right-axis'].title.text = 'revenue (m' + values['currency'] + ')';
    this.graph.data.labels = values['years'];
    this.graph.data.datasets[0].data = ratio(values['gross_profit'], values['revenue']);
    this.graph.data.datasets[1].data = ratio(values['ebitda'], values['revenue']);
    this.graph.data.datasets[2].data = ratio(values['ebit'], values['revenue']);
    this.graph.data.datasets[3].data = values['revenue'];
    this._alignZeros();
    this.graph.update();
  }

  _init(element) {
    return new Chart(element.getContext('2d'),
      {
        type: 'bar',  // overall chart type
        data: {
          labels: [],
          /* ! Set revenue in last to be *behind*: */
          datasets: [{
            type:'line',
            label: 'Profit %',
            fill:false,
            backgroundColor: 'rgb(255,211,174)',
            borderColor: 'rgb(255,211,174)',
            data: [],
            yAxisID: 'left-axis'
          }, {
            type:'line',
            label: 'EBITDA %',
            fill:false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [],
            yAxisID: 'left-axis'
          }, {
            type:'line',
            label: 'EBIT %',
            fill:false,
            backgroundColor: 'rgb(120,177,145)',
            borderColor: 'rgb(120,177,145)',
            data: [],
            yAxisID: 'left-axis'
          }, {
            label: 'Revenue',
            fill: true,
            backgroundColor: 'rgb(69,197,235)',
            borderColor: 'rgb(69,197,235)',
            data: [],
            yAxisID: 'right-axis'
          }]
        },
        options: {
          legend: { position:'bottom', usePointStyle:true },
          maintainAspectRatio: true,
          responsive: true,
          title: { display: false },
          tooltips: {mode: 'index', intersect: false},
          hover: {mode: 'nearest', intersect: true},
          scales: {
            'right-axis': {
              type:'linear',
              id:'right-axis',
              display: true,
              position: 'left',
              stacked:false,
              title: {display: true, text: 'revenue (m€)'},
              gridLines: {drawOnChartArea:true},
              ticks: {beginAtZero: true, maxTicksLimit: 7},
            },
            'left-axis': {
              type:'linear',
              id:'left-axis',
              display: true,
              position: 'right',
              title: {display: true, text: 'ratio'},
              gridLines: {drawOnChartArea:true},
              ticks: {
                callback: function(value, index, values) {
                  return (value * 100).toFixed(0) + '%';
                }
              }
            }
          }
        }
      });
  }
}

const register = () => customElements.define(tagName, ChartGraph);
window.WebComponents ? window.WebComponents.waitFor(register) : register();
