import { getFirstByClass, triggerEvent } from '../utils';


class Nav {
    constructor(container) {
        this.container = container;
        this.navs = [];
        this.selected = undefined;
        this.select = this.select.bind(this);
        Array.prototype.forEach.call(this.container.childNodes, (item) => {
            if (item.nodeType === Node.ELEMENT_NODE) {
                if (!this.selected)
                    this.selected = item;
                const idTab = item.getAttribute('data-ref');
                this.navs.push({'item': item, 'tab': document.getElementById(idTab)});
                if (item.attributes.selected)
                    this.selected = item;
                item.addEventListener('click', () => this.select(item));
            }
        });
        this.select(this.selected);
    }

    select(selected) {
        this.selected = selected;
        this.navs.forEach((e) => {
            let item = e['item'];
            let tab = e['tab'];
            if (item == selected) {
                item.setAttribute('selected', true);
                tab.style.display = 'flex';
                triggerEvent(tab, 'navShow');
            } else {
                let triggerHide = item.attributes.selected || tab.style.display !== 'none';
                item.removeAttribute('selected');
                if (triggerHide) {
                    tab.style.display = 'none';
                    triggerEvent(tab, 'navHide');
                }
            }
        });
    }
}

export const initNav = container => new Nav(container);
export default Nav;
