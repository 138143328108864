(function($)
{
    $.fn.priorityInput = function(options)
    {
        return this.each(function()
        {
            var self         = this;
            var $self        = $(self);
            var $input        = $self.find("input");

            var toggle = function(event)
            {
                event.preventDefault();
                $input.val((Number($input.val()) + 1) % 4);
                $self.attr('data-value', $input.val());
                console.log($input.val());
                $input.trigger('change');
            }

            $self.on('click keyup', toggle);
        });
    };
})(jQuery);
