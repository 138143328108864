import './FormPopup.scss';

const stopPropagation = event => event.stopPropagation();

class FormPopup {
    constructor(triggerBtn) {
        this.triggerBtn = triggerBtn;
        this.triggerBtn.addEventListener('click', this.onClick.bind(this), false);
        this.targetForm = document.getElementById(this.triggerBtn.getAttribute('target-form'));
    }

    onClick(event) {
        event.preventDefault();
        this.displayPopup();
    }

    displayPopup() {
        const container = document.createElement("DIV");
        container.setAttribute('class', 'popup');
        const hide = e => document.body.removeChild(container);
        container.addEventListener('click', hide, false);

        const popup = document.createElement("DIV");
        popup.setAttribute('class', 'popup-content');

        this.targetForm.style.display = 'block';
        let beforeSubmitEvent = new Event('beforeSubmit');

        popup.appendChild(this.targetForm);


        const footer = document.createElement("FOOTER");

        const confirmButton = document.createElement("BUTTON");
        confirmButton.setAttribute("class", "button button-important btn-submit btn btn-primary");
        confirmButton.textContent = "Confirm";
        let isAjax = this.triggerBtn.getAttribute('is-ajax');
        confirmButton.addEventListener("click", (e) => {
            if (isAjax) {
                this.targetForm.dispatchEvent(beforeSubmitEvent);
                return false;
            }
            this.targetForm.submit();
        });
        footer.appendChild(confirmButton);

        const cancelButton = document.createElement("BUTTON");
        cancelButton.setAttribute("class", "button btn-delete btn btn-secondary");
        cancelButton.textContent = "Cancel";
        cancelButton.addEventListener("click", () => {
            hide();
        });
        footer.appendChild(cancelButton);
        popup.appendChild(footer);

        popup.addEventListener('click', stopPropagation, false);
        container.appendChild(popup);


        document.body.appendChild(container);
    }
}

export const initFormPopup = btn => new FormPopup(btn);
export default FormPopup;
