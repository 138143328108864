import {getFirstByClass} from "../utils";

class Dropdown {
    constructor(container) {
        this.container = container;
        this.menu = getFirstByClass('dropdown-content', container);
        if (!this.menu)
            return //avoid perturbating bootstrap dropdown
        this.close = this.close.bind(this);
        this.mouseleave = this.mouseleave.bind(this);
        this.container.addEventListener('click', this.open.bind(this), false);
        this.container.addEventListener('mouseleave', this.mouseleave, false);

        this.menu.addEventListener('mouseover', () => {
            this.menu.classList.add('hover');
        }, false);

        this.menu.addEventListener('mouseleave', () => {
            this.menu.classList.remove('hover');
        }, false);
    }

    open(event) {
        if (!this.container.classList.contains('is-open')) {
            event.stopPropagation();
            document.addEventListener('click', this.close, false);
            this.container.classList.add('is-open');
        }
    }

    mouseleave() {
        setTimeout(() => {
                if (!this.menu.classList.contains('hover'))
                    this.container.classList.remove('is-open');
            },
            500);
    }


    close() {
        document.removeEventListener('click', this.close, false);
        this.container.classList.remove('is-open');
    }
}

export const initDropdown = container => new Dropdown(container);
export default Dropdown;
