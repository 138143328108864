import {initConfirmPopup} from './ConfirmPopup';
import {initDropdown} from './Dropdown';
import {initLoadMore, initLoadMoreUpDown} from './LoadMore';
import {initSelect} from './Select';
import {initAutoload} from './Autoload';
import {initNav} from './Nav';
import {initAffix} from './Affix';
import {initFormattedForMillions} from './utils';
import {initFormPopup} from './FormPopup';

const identityMap = o => o;

const init = root => {
    Array.prototype.forEach.call(root.getElementsByClassName('formated_for_millions'), initFormattedForMillions);
    Array.prototype.forEach.call(root.querySelectorAll('.select-component:not(.jq-select-component)'), initSelect);
    Array.prototype.forEach.call(root.getElementsByClassName('autoload'), initAutoload);
    Array.prototype.forEach.call(root.getElementsByClassName('dropdown'), initDropdown);
    Array.prototype.forEach.call(root.getElementsByClassName('nav-component'), initNav);
    Array.prototype.forEach.call(root.getElementsByClassName('affix'), initAffix);
    Array.prototype.forEach.call(Array.prototype.map.call(root.getElementsByClassName('loadmore-btn'), identityMap), initLoadMore);
    Array.prototype.forEach.call(root.getElementsByClassName('confirm-btn'), initConfirmPopup);
    Array.prototype.forEach.call(root.getElementsByClassName('btn-popup'), initFormPopup);
    Array.prototype.forEach.call(root.getElementsByClassName('load-more-up-down'), initLoadMoreUpDown);
    $(root).find('[data-toggle="tooltip"]').tooltip({delay: 100});
}

export default init;
