import { getFirstByClass, createElement, triggerEvent } from '../utils'
import init from '../init';
import api from '../api';

class Autoload {
    constructor(container) {
        this.container = container;

        this.templateLoading = getFirstByClass('autoload-loading', container);
        this.templateMore = getFirstByClass('autoload-more', container);
        this.templateError = getFirstByClass('autoload-error', container);
        this.templateEmpty = getFirstByClass('autoload-empty', container);

        this._setAttribute = this.container.setAttribute;
        this._removeAttribute = this.container.removeAttribute;
        this.container.setAttribute = this.setAttribute.bind(this);
        this.container.removeAttribute = this.removeAttribute.bind(this);
        this.onChange = this.onChange.bind(this);
        this.load = this.load.bind(this);
        this.reload = this.reload.bind(this);
        this.checkScroll = this.checkScroll.bind(this);

        this.container.addEventListener('datachange', this.onChange);
        this.container.Autoload = () => { return {
            reload: this.reload,
            load: this.load,
            checkScroll: this.checkScroll
        };};

        this.onChange();
    }

    onChange() {
        this.url = this.container.getAttribute("data-url");
        if (this.container.getAttribute("data-initial"))
            this.initalData = JSON.parse(this.container.getAttribute("data-initial"));
        if (this.container.getAttribute("data-query"))
            this.query = eval('(' + this.container.getAttribute("data-query") + ')');
        this.reload();
    }

    reload() {
        let first = this.container.firstChild;
        while (first) {
            this.container.removeChild(first);
            first = this.container.firstChild;
        }
        delete this.more;
        delete this.loading;
        delete this.error;
        this.offset = 0;
        this.has_more = true;
        this.initialLoadDone = false;
        this.load();
    }

    load() {
        if (!this.has_more) {
            return
        }

        if (this.more) {
            this.more.parentNode.removeChild(this.more);
            delete this.more;
        }
        if (!this.initialLoadDone && this.initalData !== undefined) {
            return this.loadLocally(this.initalData);
        }

        if (this.url) {
            if (this.container.offsetParent !== null) {
                if (this.templateLoading)
                    this.loading = this.appendTemplate(this.templateLoading);
                api.get(this.url, { params: { ...this.query, offset: this.offset }})
                    .then(r => {
                        this.loadLocally(r.data);
                    }).catch(e => {
                        console.error(e.message);
                        this.loadLocally({"has_error": true});
                    });
            } else {
                if (this.container.parentElement)
                    this.container.parentElement.addEventListener('navShow', () => this.load());
            }
        }
    }

    loadLocally(data) {
        if (this.loading) {
            this.loading.parentNode.removeChild(this.loading);
            delete this.loading;
        }
        this.offset = data.offset;
        let contentExist = false;
        if (data.content){
            const content = createElement("DIV");
            content.innerHTML = data.content;
            contentExist = content.firstElementChild;
            init(content);
            let first = content.firstChild;
            while (first) {
                this.container.appendChild(first);
                first = content.firstChild;
            }
        }
        if (!this.initialLoadDone && !contentExist && this.templateEmpty) {
            this.empty = this.appendTemplate(this.templateEmpty);
        }
        if (data.has_more && this.templateMore) {
            this.more = this.appendTemplate(this.templateMore);
            this.more.addEventListener('click', this.load);
            this.setupInfitineScroll(this.more);
        }
        if (data.has_error && this.templateError) {
            this.error = this.appendTemplate(this.templateError);
        }
        this.initialLoadDone = true;
    }

    appendTemplate(template) {
        const clone = document.importNode(template.content, true);
        Array.prototype.forEach.call(clone, (e) => {
            if (e.nodeType === Node.ELEMENT_NODE)
                init(e);
        });
        this.container.appendChild(clone);
        return this.container.lastElementChild;
    }

    setAttribute() {
        this.container._addAttribute(...arguments);
        if (arguments[0] in ["data-initial", "data-url", "data-query"])
            triggerEvent(this.container, 'datachange');
    }

    removeAttribute() {
        this.container._removeAttribute(...arguments);
        if (arguments[0] in ["data-initial", "data-url", "data-query"])
            triggerEvent(this.container, 'datachange');
    }

    resize(element) {
        element.style.height = '5px';
        element.style.height = (element.scrollHeight+5)+'px';
    }

    setupInfitineScroll(btn) {
        let parent = btn;
        while (parent !== null && !parent.classList.contains('infinitescroll')) {
            parent = parent.parentElement;
        }
        if (parent === null) {
            return;
        }
        const onScroll = event => {
            if (btn.isConnected) {
                const isVisible = btn.getBoundingClientRect().y - parent.getBoundingClientRect().y - parent.clientHeight < 0;
                if (isVisible) {
                    btn.click();
                }
            }
        };
        parent.addEventListener('scroll', onScroll, false);
        this.scrollParent = parent;
        onScroll();
    }

    checkScroll() {
        if (this.scrollParent){
            triggerEvent(this.scrollParent, 'scroll');
        }
    }
}


export const initAutoload = container => new Autoload(container);
export default Autoload;
