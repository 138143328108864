(function($)
{
    $.fn.starInput = function(options)
    {
        return this.each(function()
        {
            var self = this;
            var $self = $(self);
            var $input = $self.find("input");

            if ($input.is(":checked")) {
                $self.find(".sourcing-star").addClass("sourcing-star-active")
            }
        });
    };
})(jQuery);
