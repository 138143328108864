/*
by adlb
*/

(function ($) {

    var processValidResponse = function ($input, settings, response) {
        settings.listElement.html(response.responseText);
    }

    var processErrorResponse = function ($input, settings, response) {
        settings.listElement.html("Error");
    }

    var updateList = function($input, query, settings) {
        $.ajax({
            type: "GET",
            url: settings.url + '?q=' + encodeURIComponent(query),
            contentType: false,
            processData: false,
            complete: function(response) {
                if (response.status === 200) {
                    processValidResponse($input, settings, response);
                } else {
                    processErrorResponse($input, settings, response);
                }
            }
        });
    }

    var changeInInput = function ($input, settings) {
        settings.listElement.html("");
        let query = $input.val();
        if (query.length < 5) {
            clearTimeout(settings.tiemout);
        } else {
            clearTimeout(settings.timeout);
            settings.timeout = setTimeout(function (){ updateList($input, query, settings); }, settings.delay_in_ms);
        }
    }

    $.fn.quickSearch = function (options) {

        // Default settings
        var defaults = {
            "delay_in_ms": 100,
        };

        this.each(function () {
            let $input = $(this)
            let settings = $.extend({}, defaults, options, $input.data());
            settings.listElement = $("#" + settings.target);
            $input.on('keyup', function (event) {
                event.preventDefault();
                changeInInput($input, settings);
                return false;
            });
        });

        return this;
    };

}(jQuery));
