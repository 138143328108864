import axios from 'axios';


const getCookie = function(name) {
    if (document.cookie && document.cookie !== '') {
        var cookiestring = RegExp(`${ name }[^;]+`).exec(document.cookie);
        return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
    }
    return "";
}

export const api = axios.create({
    headers: {
        "X-CSRFToken": getCookie('csrftoken'),
        "X-Requested-With": 'XMLHttpRequest'
    }
});

export const isCancel = axios.isCancel;
export default api;
