import api from '../api';
import init from '../init';

export const initLoadMore = btn => {
    btn.addEventListener('click', event => {
        event.stopPropagation();
        const url = btn.getAttribute('data-url');
        const loader = document.createElement(btn.tagName);
        loader.setAttribute('class', 'loader');
        btn.parentElement.insertBefore(loader, btn);
        btn.parentElement.removeChild(btn);

        api.get(url)
            .then(r => {
                const res = document.createElement("DIV");
                $(res).html(r.data);
                const data = res.firstElementChild;
                init(data);
                while (data.firstElementChild !== null) {
                    loader.parentElement.insertBefore(data.firstElementChild, loader);
                }
                loader.parentElement.removeChild(loader);
            })
            .catch(e => console.log("error", e));
    }, false);

    let parent = btn;
    while (parent !== null && !parent.classList.contains('infinitescroll')) {
        parent = parent.parentElement;
    }
    if (parent !== null) {
        const onScroll = event => {
            if (!btn.isConnected) {
                const btns = parent.getElementsByClassName('loadmore-btn');
                if (btns.length > 0) {
                    btn = btns[0];
                }
            }
            if (btn.isConnected) {
                const isVisible = btn.getBoundingClientRect().y - parent.getBoundingClientRect().y - parent.clientHeight <= 0;
                if (isVisible) {
                    btn.click();
                }
            }
        };
        parent.addEventListener('scroll', onScroll, false);
        onScroll();
    }
}

class LoadMoreUpDown {
    constructor(container) {
        this.container = container;
        this.topUrl = this.container.getAttribute('top-data-url');
        this.bottomUrl = this.container.getAttribute('bottom-data-url');
        this.offsetJump = this.container.getAttribute('offset-limit');
        if (!this.offsetJump) {
            this.offsetJump = 6;
        } else {
            this.offsetJump = int(this.offsetJump);
        }
        this.scrollTopBtn = document.createElement('button');
        this.scrollTopBtn.innerText = 'load more';
        this.scrollTopBtn.style.display = 'none';

        this.scrollBottomBtn = document.createElement('button');
        this.scrollBottomBtn.innerText = 'load more';
        this.scrollBottomBtn.style.display = 'none';

        this.container.scrollTop = 2;
        this.container.insertBefore(this.scrollTopBtn, this.container.firstChild)
        this.container.appendChild(this.scrollBottomBtn);

        this.isTopFirstime = true;
        this.container.addEventListener('scroll', e => {
            if (this.container.scrollHeight <= this.container.scrollTop + this.container.clientHeight) {
                this.scrollBottomBtn.click();
            } else if (this.container.scrollTop === 0) {
                this.scrollTopBtn.click();
                if (this.isTopFirstime && this.topUrl) {
                    this.scrollTop();
                    this.isTopFirstime = false;
                }
            }
        });
        this.scrollBottom();
    }


    async getContent(offset, top = false) {
        let url = this.buildUrl(offset, top);
        let result = null;

        await api.get(url).then(r => {
            result = r;
        }).catch(err => {
            this.container.innerHTML = '<span class="text-danger">something went wrong.</span>'
        });
        return new Promise(resolve => {
            resolve(result);
        });
    }

    async* events(el /* HTMLElement to attach the event to */,
                  name /* name of the event eg. 'scroll' */,
                  condition /* Condition to check on each trigger */) {
        let resolve;
        el.addEventListener(name, e => {
            if (condition(e)) {
                // Resolve the promise whenever the supplied condition is truthy
                resolve(e);
            }
        });
        while (true) {
            // Emitting when the conditional event promise resolves
            yield await new Promise(_resolve => resolve = _resolve);
        }
    }

    buildUrl(offset, top = false) {
        if (top) {
            return this.topUrl.replace('00000', offset)
        }
        return this.bottomUrl.replace('00000', offset)
    }

    async* items(top = false) {
        let offset = 0;
        while (true) {
            yield await this.getContent(offset, top);
            offset += this.offsetJump;
        }
    }

    createElementFromHTML(htmlString) {
        let div = document.createElement('div');
        $(div).html(htmlString.trim());
        return div.firstChild;
    }

    async scrollBottom() {
        // The conditional event stream.
        let eventIterator = this.events(this.scrollBottomBtn, 'click', () => true);
        // Iterating over the items stream, appending items
        // as they arrive.
        for await (const page of this.items()) {
            let data = this.createElementFromHTML(page.data);
            this.container.appendChild(data);
            // Await the next emission of the conditional event.
            await eventIterator.next();
        }

    }

    async scrollTop() {
        // The conditional event stream.
        let eventIterator = this.events(this.scrollTopBtn, 'click', () => true);
        // Iterating over the items stream, appending items
        // as they arrive.
        for await (const page of this.items(true)) {
            let data = this.createElementFromHTML(page.data);
            this.container.insertBefore(data, this.container.firstChild);
            this.container.scrollTop = data.clientHeight + 3;
            //this.offsetJump = int(data.getElementsByTagName('input')[0].value)
            await eventIterator.next();
        }

    }
}

export const initLoadMoreUpDown = container => new LoadMoreUpDown(container);
