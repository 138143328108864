(function ($) {
    // [options]
    $.fn.loadAsync = function (options) {
        var defaults = {
            load: undefined
        };

        if (options instanceof String){
            options = {load: options};
        }

        var settings = $.extend({}, defaults);

        this.each(function () {
            var data = {load: $(this).attr('data-load')};
            localSettings = $.extend(settings, data, options);
            if (localSettings.load)
                $(this).attr('data-load', localSettings.load);
                $(this).load(localSettings.load, function() {
                    $(this).trigger('loadasync.loaded');
                });
        });
    }
}(jQuery));
