export const initFormattedForMillions = (elem) => {

}
(function($)
{
    $.fn.formatForMillions = function(options)
    {
        return this.filter('input').each(function()
        {
            var self         = this;
            var $self        = $(self);

            let $real_input = $self.clone();
            $self.removeAttr('id');
            let name = $self.attr("name");
            $real_input.css("display", "none");
            $self.attr("name", name + "_raw");
            $real_input.insertAfter($self);

            if (self.getAttribute("type") === 'number')
                self.setAttribute("type", undefined)

            let fixDisplay = function() {
                let newValue = '';
                let start = self.selectionStart;
                let value = self.value.split('.')[0];
                for(let i = value.length-1; i>=0; i--) {
                    if (value[i].match(/\d/))
                        newValue = value[i] + newValue;
                    else if (start > i)
                        start--;
                    if (newValue.length > 0 && newValue.length % 4 === 0) {
                        newValue = newValue[0] + ' ' + newValue.slice(1);
                        if (start > i + 1)
                            start++;
                    }
                }
                if (newValue !== self.value) {
                    self.value = newValue;
                    self.setSelectionRange(start, start);
                }
                $real_input.val(newValue.replaceAll(" ", ""));
            }
            fixDisplay();

            self.addEventListener('keyup', fixDisplay);
            self.addEventListener('change', fixDisplay);
        });
    };
})(jQuery);
