(function($)
{
    $.fn.toggleCheckbox = function(options)
    {
        return this.filter('select').each(function()
        {
            var self         = this;
            var $self        = $(self);
            var list         = $self.next();
            var $list        = $(list);

            var display = function() {
                $list.find("span").html($self.find('option:selected').text());
                $list.find("[data-val=" + $self.val() + "]").addClass("selected")
                $list.find("[data-val!=" + $self.val() + "]").removeClass("selected")
            }

            var toggle = function(event)
            {
                if ($self.val() === 'false') $self.val('unknown');
                else if ($self.val() === 'true') $self.val('false');
                else $self.val('true');
                display();
            }

            $list.on('click keyup', toggle);

            display();
        });
    };
})(jQuery);
