import {getFirstByClass, getFirstByTag, triggerEvent} from '../utils'
import {CancelToken} from 'axios';
import {api, isCancel} from '../api';

import "./Select.scss";

class PureSelect {
    constructor(container) {
        this.container = container;
        this.wrapContainer = getFirstByClass('select-component-wrap', this.container);
        this.valueInput = getFirstByClass('select-component-wrap-value', this.container);
        this.labelInput = getFirstByClass('select-component-wrap-label', this.container);
        this.optionsContainer = getFirstByClass('select-component-options', this.container);
        this.toggleIcon = getFirstByClass('select-component-wrap-icon', this.container);
        this.autocomplete_value = this.optionsContainer.getAttribute('autocomplete') === 'true';
        if (this.toggleIcon) {
            this.toggleIcon.addEventListener('mousedown', (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!this.container.classList.contains('is-open'))
                    this.labelInput.focus();
                else
                    this.labelInput.blur();
            });
        }
        this.container.addEventListener('mousedown', (e) => {
            e.preventDefault();
            this.labelInput.focus();
        });

        if (this.autocomplete_value) {
            this.labelInput.addEventListener('focusout', () => {
                this.labelInput.value = '';
            });
            this.labelInput.addEventListener('keyup', () => this.goFocus());
        } else {
            this.labelInput.addEventListener('keydown', (e) => this.keyDown(e));
        }
        this.labelInput.addEventListener('focus', () => this.goFocus());
        this.labelInput.addEventListener('blur', () => this.goBlur());
        this.labelInput.addEventListener('mousedown', (e) => this.mousedown(e));

        this.valueInput.addEventListener('focus', () => this.goFocus());
        this.valueInput.addEventListener('blur', () => this.goBlur());
        this.valueInput.addEventListener('keydown', (e) => this.keyDown(e));
        this.valueInput.addEventListener('mousedown', (e) => this.mousedown(e));

        let self = this;
        Object.defineProperty(this.container, "value", {
            get: () => {
                return self.value;
            },
            set: (v) => {
                self.value = v;
            }
        });
        this.container._setAttribute = this.container.setAttribute;
        this.container._removeAttribute = this.container.removeAttribute;
        this.container._addEventListener = this.container.addEventListener;

        this.container.setAttribute = self.setAttribute.bind(this);
        this.container.removeAttribute = self.removeAttribute.bind(this);
        this.container.addEventListener = self.addEventListener.bind(this);
        Object.defineProperty(this.container, "type", {
            get: () => {
                return 'select-custom';
            }
        });
        this._remoteOptionsLoaded = false;

        this.initOptionsLocally(this.optionsContainer);
    }

    setAttribute() {
        this.container._setAttribute(...arguments);
        if (arguments[0] === 'disabled')
            this.labelInput.setAttribute(...arguments);
        this.valueInput.setAttribute(...arguments);
    }

    removeAttribute() {
        this.container._removeAttribute(...arguments);
        if (arguments[0] === 'disabled')
            this.labelInput.removeAttribute(...arguments);
        this.valueInput.removeAttribute(...arguments);
    }

    addEventListener() {
        this.container._addEventListener(...arguments);
    }

    goFocus() {
        if (this.container.classList.contains('is-open')) {
            this.container.classList.remove('is-open');
        }
        this.initOptions(this.optionsContainer, this.labelInput).then(() => {
            this.container.classList.add('is-open');
        });
    }

    goBlur() {
        if (this.container.classList.contains('is-open')) {
            this.container.classList.remove('is-open');
        }
    }

    get value() {
        return {"id": this.valueInput.value, "label": this.labelInput.value};
    }

    set value(value) {
        if (value === undefined)
            value = {
                "id": "",
                "label": ""
            };
        this.select(value);
    }

    select(value) {
        if (typeof value !== 'object')
            throw "You must select a value with form {id:..., label:...}";

        if (this.valueInput.value === value.id && this.labelInput.value === value.label)
            return;

        this.labelInput.value = value.label || '';
        this.valueInput.value = value.id || '';

        triggerEvent(this.container, 'change');
        this.labelInput.blur();
    }

    keyDown(event) {
        const char = event.charCode || event.keyCode;
        if (char === 9) // key TAB
            return;
        if (char === 13) // key ENTER
            this.labelInput.blur();
        if (char === 27) // key ESC
            this.labelInput.blur();
        event.preventDefault();
    }

    mousedown(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.container.attributes.disabled) {
            if (!this.container.classList.contains('is-open'))
                this.labelInput.focus();
            else
                this.labelInput.blur();
        }
    }

    initOptions(optionsContainer, labelInput = null) {
        let optionCall = optionsContainer.getAttribute('data-loader');
        if (optionCall) {
            return this.initOptionsRemotely(optionsContainer, optionCall, labelInput);
        } else {
            return this.initOptionsLocally(optionsContainer);
        }
    }

    initOptionsRemotely(optionsContainer, optionCall, labelInput) {
        let autocomplete_value = optionsContainer.getAttribute('autocomplete');

        if (this._remoteOptionsLoaded && autocomplete_value) {
            optionsContainer.innerHTML = '<template><li data-value="@@id@@"><span>@@label@@</span></li></template>'
        } else if (this._remoteOptionsLoaded) {
            return Promise.resolve();
        }

        let query = null;
        if (autocomplete_value === 'true' && labelInput != null) {
            query = labelInput.value;
        }

        let itemTemplate = getFirstByTag('template', optionsContainer).content;

        return api.get(optionCall, {
            params: {
                q: query
            }
        }).then(r => {
            Array.prototype.forEach.call(r.data, (r) => {
                let clone = itemTemplate.cloneNode(true).firstChild;
                clone.setAttribute('data-value', r.id);
                clone.innerHTML = clone.innerHTML.replace(/@@label@@/g, r.label);
                clone.innerHTML = clone.innerHTML.replace(/@@id@@/g, r.id);
                clone.addEventListener('mousedown', (e) => {
                    e.stopPropagation();
                    this.select({'id': r.id, 'label': r.label});
                });
                optionsContainer.appendChild(clone);
            });
            this._remoteOptionsLoaded = true;
        });
    }

    initOptionsLocally(optionsContainer) {
        let options = optionsContainer.childNodes;
        let selected;
        Array.prototype.forEach.call(options, (option) => {
            if (option.tagName === 'LI') {
                let id = option.getAttribute('data-value') || '';
                let label = option.getAttribute('data-label') || option.innerHTML;
                option.addEventListener('mousedown', (e) => {
                    e.stopPropagation();
                    this.select({'id': id, 'label': label});
                });
                if (option.attributes.selected)
                    selected = {'id': id, 'label': label};
            }
        });
        if (selected !== undefined)
            this.select(selected);
        return Promise.resolve();
    }
}

class Autocomplete extends PureSelect {
    constructor(container) {
        super(container);
        this.keyUp = this.keyUp.bind(this);
        this.labelInput.addEventListener('keyup', this.keyUp);
        this.url = this.container.getAttribute('data-autocomplete');
    }

    keyDown(event) {
        const char = event.charCode || event.keyCode;
        switch (char) {
            case 13: // key ENTER
            case 27: // key ESC
                this.labelInput.blur();
                event.preventDefault();
                break;
        }
        return true;
    }

    keyUp(event) {
        event.stopPropagation();
        this.valueInput.value = this.labelInput.value;
        const query = this.labelInput.value.trim().toLowerCase();
        if (query !== this.lastQuery) {
            if (this.currentRequest) {
                this.currentRequest.cancel();
            }
            this.lastQuery = query;
            this.currentRequest = CancelToken.source();
            api.get(this.url, {
                params: {
                    q: query
                },
                cancelToken: this.currentRequest.token
            }).then(r => {
                this.optionsContainer.textContent = "";
                r.data.objects.forEach(result => {
                    const item = document.createElement("LI");
                    item.setAttribute("class", "select-container-options-item");
                    item.textContent = result;
                    item.setAttribute("data-value", result);
                    item.addEventListener('mousedown', () => this.select({'id': result, 'label': result}), true);
                    this.optionsContainer.appendChild(item);
                });
            }).catch(e => {
                if (isCancel(e)) {
                    console.log("Request Autocomplete Cancelled.");
                } else {
                    console.error(e);
                }
            });
        }
    }

    mousedown() {
        return;
    }
}

class Select {
    constructor(container) {
        if (container.selectInitialized == true)
            return;
        else
            container.selectInitialized = true;
        if (container.getAttribute('data-autocomplete'))
            return new Autocomplete(container);
        else
            return new PureSelect(container);
    }
}

export const initSelect = container => new Select(container);
export default Select;

(function ($) {
    $.fn.selectComponent = function () {
        this.each(function () {
            new Select(this);
        });

        return this;
    };

}(jQuery));
