const tagName = 'financial-table';
const template = document.createElement('template');
template.innerHTML = `
<style>
    .financial-data-table .revenue,
    .financial-data-table .gross-profit,
    .financial-data-table .ebitda {
        font-weight: bold;
        text-align: left;
    }
    .financial-data-table .hidden {
        display: none;
    }
    .financial-data-table .has-error {
        border: 1px solid red;
        box-sizing: border-box;
    }
    .financial-data-table thead th {
        background-color: #657ca2;
        color: white;
        vertical-align: middle;
    }
    .financial-data-table .button {
        margin: 5px 10px;
        padding: 0 6px;
    }
    /* hack for empty cell content editable in Firefox */
    table.financial-data-table {
        empty-cells: show;
    }
    .financial-data-table td div[contentEditable="true"] {
        min-height: 24px;
    }
</style>
<input type="hidden">
<table class="table table-sm table-bordered table-striped financial-data-table">
  <thead>
    <tr>
      <th>in m€</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td contenteditable="false">Revenue</td>
    </tr>
    <tr>
      <td contenteditable="false">Gross profit</td>
    </tr>
    <tr>
      <td contenteditable="false">Ebitda</td>
    </tr>
  </tbody>
</table>
<div class="has-error" style='display:none;'></div>`;

class FinancialTable extends HTMLElement {
  constructor() {
    super();
    this.innerValue = null;
  }

  static get observedAttributes() {
    return ['value'];
  }

  static convertToInnerValue(str) {
    if (str === 'None' || str === null)
      return null;
    let value = JSON.parse(str);
    return value;
  }

  static convertToValue(innerValue) {
    delete innerValue['Ebitda'];
    delete innerValue['Revenue'];
    delete innerValue['Gross profit'];
    return JSON.stringify(innerValue);
  }

  safeSetAttribute(name, value) {
    if (this.getAttribute(name) !== value) {
      this.setAttribute(name, value);
      this._redrawTable();
    }
  }

  attributeChangedCallback(name, oldVal, newVal) {
    this[name] = newVal;
  }

  _onChange(event) {
    const cell = event.target;
    const rowIndex = cell.parentNode.rowIndex;
    const columnIndex = cell.cellIndex;
  
    if (rowIndex > 0 && columnIndex > 0) {
      const metric = this.table.rows[rowIndex].cells[0].textContent;
      const year = this.table.rows[0].cells[columnIndex].textContent;
      let value = cell.textContent.trim();
  
      // Filtrer les caractères non numériques (y compris la décimale et le signe négatif)
      value = value.replace(/[^0-9.-]/g, '');
  
      if (!this.innerValue) {
        this.innerValue = {};
      }
  
      if (!this.innerValue[year]) {
        this.innerValue[year] = {};
      }
  
      this.innerValue[year][metric] = value;
    }
  }  

  set value(value) {
    this.safeSetAttribute('value', value);
    if (this.input)
      this.input.value = value;
    this.innerValue = FinancialTable.convertToInnerValue(value);
  }

  get value() {
    return this.getAttribute('value');
  }

  connectedCallback() {
    this.innerValue = FinancialTable.convertToInnerValue(this.getAttribute('value')) || null;
    this.disabled = this.getAttribute('disabled') !== null || false;

    var element = document.importNode(template.content, true);
    this.appendChild(element);
    this.input = this.querySelector('input');
    this.input.setAttribute('name', this.getAttribute('name'));
    this.input.value = this.getAttribute('value');
    this.table = this.querySelector('.table');
    this.error = this.querySelector('.has-error');

    this._redrawTable();
  }

  disconnectedCallback() {
    /* do cleanup stuff here */
  }

  _updateTableValue() {
    if (!this.innerValue) {
      this.innerValue = {};
    }
  
    const rows = this.table.querySelectorAll('tbody tr');
    const years = Object.keys(this.innerValue).filter(year => parseInt(year));
    const metrics = ['Revenue', 'Gross profit', 'Ebitda'];
  
    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('td');
      const metric = metrics[rowIndex];
      if (!this.innerValue[metric]) {
        this.innerValue[metric] = {};
      }
  
      for (let i = 1; i < cells.length; i++) {
        const cell = cells[i];
        const year = years[i - 1];
        const value = cell.textContent.trim();
        this.innerValue[metric][year] = value;
      }
    });
    delete this.innerValue['Ebitda'];
    delete this.innerValue['Revenue'];
    delete this.innerValue['Gross profit'];
    this.value = FinancialTable.convertToValue(this.innerValue);
  }
  

  _redrawTable() {
    var thead = this.table.querySelector('thead');
    var tbody = this.table.querySelector('tbody');
    thead.innerHTML = '';
    tbody.innerHTML = '';

    if (this.innerValue) {
      var years = Object.keys(this.innerValue).filter(year => parseInt(year));
      // Générer les en-têtes de colonnes
var theadRow = document.createElement('tr');
thead.appendChild(theadRow);

var metricHeader = document.createElement('th');
metricHeader.textContent = 'Metric';
theadRow.appendChild(metricHeader);

years.forEach(year => {
  var yearHeader = document.createElement('th');
  yearHeader.textContent = year;
  theadRow.appendChild(yearHeader);
});

  // Générer les lignes de données
  var metrics = ['Revenue', 'Gross profit', 'Ebitda'];
  metrics.forEach(metric => {
  var row = document.createElement('tr');
  var metricCell = document.createElement('td');
  metricCell.textContent = metric;
  metricCell.style.fontWeight = 'bold';
  metricCell.style.textAlign = 'left';
  row.appendChild(metricCell);

  years.forEach(year => {
    var valueCell = document.createElement('td');
    valueCell.contentEditable = !this.disabled;
    valueCell.textContent = this.innerValue[year] && this.innerValue[year][metric] ? this.innerValue[year][metric].toString() : '';
    valueCell.addEventListener('input', this._onChange.bind(this));
    valueCell.addEventListener('blur', () => {
      this.value = FinancialTable.convertToValue(this.innerValue);
    });
    row.appendChild(valueCell);
  });

  tbody.appendChild(row);
});


  
    }
  }
}

customElements.define(tagName, FinancialTable);
