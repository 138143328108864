import api from '../api';

import './ConfirmPopup.scss';

const stopPropagation = event => event.stopPropagation();

class ConfirmPopup {
    constructor(triggerBtn) {
        this.triggerBtn = triggerBtn;
        this.triggerBtn.addEventListener('click', this.onClick.bind(this), false);
    }

    onClick(event) {
        event.preventDefault();
        this.displayPopup();
    }

    displayPopup() {
        const container = document.createElement("DIV");
        container.setAttribute('class', 'popup');
        const hide = e => document.body.removeChild(container);
        container.addEventListener('click', hide, false);

        const popup = document.createElement("DIV");
        popup.setAttribute('class', 'popup-content');

        const p = document.createElement("P");
        p.textContent = this.triggerBtn.getAttribute('data-message') || "Are you sure?";
        popup.appendChild(p);

        const footer = document.createElement("FOOTER");

        const confirmButton = document.createElement("BUTTON");
        confirmButton.setAttribute("class", "btn btn-primary");
        confirmButton.setAttribute("id", this.triggerBtn.getAttribute("confirm-button-id"));
        confirmButton.textContent = "Confirm";
        confirmButton.addEventListener("click", () => {
            api.request({
                url: this.triggerBtn.getAttribute('href'),
                method: this.triggerBtn.getAttribute('data-method') || 'GET'
            })
                .then(() => {
                    hide();
                    let redirection = this.triggerBtn.getAttribute('data-redirectto');
                    if (redirection === 'remove_parent')
                        this.triggerBtn.parentElement.remove();
                    else
                        document.location = this.triggerBtn.getAttribute('data-redirectto');

                })
                .catch((e) => {
                    console.log("error", e)
                    let data = e.response.data;
                    if (Object.keys(data).length > 0) {
                        alert(data.msg);
                    }
                })
        });
        footer.appendChild(confirmButton);

        const cancelButton = document.createElement("BUTTON");
        cancelButton.setAttribute("class", "btn btn-secondary mr-2");
        cancelButton.textContent = "Cancel";
        cancelButton.addEventListener("click", () => {
            hide();
        });
        footer.appendChild(cancelButton);
        popup.appendChild(footer);

        popup.addEventListener('click', stopPropagation, false);
        container.appendChild(popup);
        document.body.appendChild(container);
    }
}

export const initConfirmPopup = btn => new ConfirmPopup(btn);
export default ConfirmPopup;
