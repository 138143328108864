import accentFold from './accentFold';

export {accentFold};

export const triggerEvent = (element, type) => {
    if(document.createEvent){
        const event = document.createEvent("HTMLEvents");
        event.initEvent(type, true, true);
        event.eventName = type;
        element.dispatchEvent(event);
    } else {
        const event = document.createEventObject();
        event.eventName = type;
        event.eventType = type;
        element.fireEvent("on" + event.eventType, event);
    }
}

export const createElement = (nodeName, className, content) => {
    const element = document.createElement(nodeName);
    if (className) {
        element.setAttribute('class', className);
    }
    if (content) {
        if (typeof content === "string") {
            element.textContent = content;
        }
        else if(content instanceof window.Node) {
            element.appendChild(content);
        }
    }
    return element;
}

export const getFirstByClass = (className, node=document) => {
    const res = node.getElementsByClassName(className);
    if (res.length > 0) {
        return res[0];
    }
    return null;
}

export const getFirstByTag = (tagName, node=document) => {
    const res = node.getElementsByTagName(tagName);
    if (res.length > 0) {
        return res[0];
    }
    return null;
}

const setClass = (elem, className) => {
    if (!elem.classList.contains(className))
        elem.classList.add(className);
}

const removeClass = (elem, className) => {
    if (elem.classList.contains(className))
        elem.classList.remove(className);
}

export const setOrRemoveClass = (elem, className, disp) => {
    if (disp)
        setClass(elem, className);
    else
        removeClass(elem, className);
}

export const setOrRemoveAttribute = (elem, attributeName, disp, value = true) => {
    if (disp)
        elem.setAttribute(attributeName, value);
    else
        elem.removeAttribute(attributeName);
}

export const stopPropagation = event => event.stopPropagation();

export const initFormattedForMillions = (elem) => {
    let fixDisplay = function() {
        let newValue = '';
        let start = elem.selectionStart;
        for(let i = elem.value.length-1; i>=0; i--) {
            if (elem.value[i].match(/\d/))
                newValue = elem.value[i] + newValue;
            else if (start > i)
                start--;
            if (newValue.length > 0 && newValue.length % 4 === 0) {
                newValue = newValue[0] + ' ' + newValue.slice(1);
                if (start > i + 1)
                    start++;
            }
        }
        if (newValue !== elem.value) {
            elem.value = newValue;
            elem.setSelectionRange(start, start);
        }
    }
    elem.addEventListener('keyup', fixDisplay);
    elem.addEventListener('change', fixDisplay);
}
