import { getFirstByClass } from '../utils';

import './Affix.scss';

class Affix {
    constructor(container) {
        this.container = container;
        this.content = container.nextElementSibling
        if (this.content)
            this.initialPadding = this.content.style.paddingTop;

        window.onscroll = this.stick.bind(this);
    }

    stick() {
        var sticky = this.container.offsetTop;
        var height = this.container.offsetHeight

        if (window.pageYOffset > sticky) {
            this.container.classList.add("sticky");
            if (this.content) {
                this.content.style.paddingTop = height;
            }
        } else {
            this.container.classList.remove("sticky");
            if (this.content) {
                this.content.style.paddingTop = this.initialPadding;
            }
        }
    }
}

export const initAffix = container => new Affix(container);
export default Affix;
